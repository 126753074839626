// The Window interface represents a window containing a DOM  document;

//
// Add window functionality here:
//

/**
 * Browser Name
 * @type {string}
 */
const browserName = window.navigator.appName;

/**
 * Deprecated: This feature is no longer recommended.
 * indicating whether the device is a mobile device
 * @return {boolean} returns a boolean, true if this is a mobile device.
 */
const isMobile = () => {
  try {
    return window?.navigator?.userAgentData?.mobile ?? false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * Deprecated: This feature is no longer recommended.
 * get the platform of the browser
 * @return {string|undefined} returns a string, representing the platform of the browser
 */
const getPlatform = () => {
  try {
    return window?.navigator?.userAgentData?.platform;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export { browserName, isMobile, getPlatform };
